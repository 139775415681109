import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
     
    <!-- <input  (input)="onSearchChange($event.target.value)"  [attr.type]="field.type" class="form-control d-none"  [id]="field.name" [name]="field.name" [formControl]="form"> -->
    <!-- <input type="checkbox"  [checked]="checked" [formControl]="form" (change)="onCheckboxChange($event)"   /> -->
    <mat-checkbox
      [checked]="checked"
      (change)="onCheckboxChange($event.checked)" [formControl]="form"
    >
    </mat-checkbox>
    `
})
export class CheckBoxComponent{
    @Input() field:any = {};
    @Input() form:UntypedFormControl;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; }
    
    @Input() checked:boolean
    constructor() { 
    }

    onSearchChange(Value: string): void {  

        if(Value == 'true' || Value == '1')
            this.checked = true
        else if(Value == 'false' || Value == '0')
        this.checked = false
        else
        this.checked = false

      }

    onCheckboxChange(e)
    {
        // this.checked=e.target.checked
        console.log('e.target.value',e.target.checked);
        
        if(this.checked == true)
        new UntypedFormControl(e.target.checked)
        else
        new UntypedFormControl(e.target.checked)
    }
}